.table-page {
  width: 50%;
  margin-left: 25%;
  padding-top: 17vh;
  max-height: 100vh;
  min-height: 87vh;
}

/* .table_div {
  margin-top: 5vh;
  border: 1px solid white;
  box-shadow: rgb(25, 191, 217) 0px 10px 15px -3px,
    rgb(25, 191, 217) 0px 4px 6px -2px;
} */

.table {
  background-color: transparent;
  color: white;
}
.tabela-dobitnici{
  color:white;
  font-family: "TcccUnityHeadLineStBold";
  text-align: left;
}
.tabela-dobitnici td{
  width: 50%;
}

.tabela-dobitnici-mobile{
  color:white;
  font-family: "TcccUnityHeadLineStBold";
  text-align: left;
  /* margin-left: 16%; */
}
.tabela-dobitnici-mobile td{
  width: 50%;
}

.table tr {
  color: white;
}

.date_picker th,
tbody td {
  width: 2.375em !important;
  height: 2.375em !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #0963a2 !important;
  border: 1px double #0963a2 !important;
  outline: none;
}

.date_picker input {
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}

@media (max-width: 1300px) {
  .table-page {
    width: 58%;
  }
}

@media (max-width: 1100px) {
  .table-page {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .table-page {
    width: 65%;
  }
}

@media (max-width: 800px) {
  .table-page {
    width: 60%;
    margin-left: 30%;
  }
}

@media (max-width: 700px) and (min-height: 500px) {
  .table-page {
    width: 90%;
    margin-left: 5%;
    padding-top: 14vh;
  }

  .table_page_content {
    margin-top: 70px;
  }

  .table_page_content-mobile {
    margin-top: none;
  }

  .date_picker {
    position: relative;
    max-height: 30%;
  }
}

@media (max-width: 600px) {
  .table-page {
    width: 80%;
    margin-left: 10%;
    padding-top: 14vh;
  }
}

@media (max-width: 550px) {
  .table-page {
    padding-top: 13vh;
  }

  .table_div {
    margin-top: 3vh;
  }

  .table_div-mobile {
    margin-top: none;
  }

  .table-page {
    width: 92%;
    margin-left: 4%;
  }
}

@media (max-height: 800px) {
  .table-page {
    padding-bottom: 30px;
    min-height: 87vh;
  }
}

@media (max-height: 500px) {
  .table-page {
    margin-left: 20%;
    min-height: 150vh;
  }
}

@media (max-height: 500px) and (max-width: 700px) {
  .table-page {
    width: 80%;
    margin-left: 10%;
    max-height: 15vh;
    padding-top: 20vh;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) and (max-height: 750px) {
  .table-page {
    margin-left: 22%;
  }
}
