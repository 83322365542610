@import "variables";
@import "~normalize.css";

.App {
  text-align: center;
  // background: url(../../public/homepage-desktop.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slazemse {
  color: white !important;
}
.row {
  margin: 0;
}

.desktop-content,
.mobile-content {
  display: none;
}
.nav-button-desktop {
  // padding: 0 15px !important;
  margin: 10px 0;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  // Senka ispod donje ivice
}
.nav-buttons {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3); // Senka ispod donje ivice

  .border-right {
    border-right: 1px solid rgba(124, 124, 124, 1);
    padding-top: 0 !important;
    // margin-top: 10px;

    &:last-child {
      border: 0;
    }
  }

  a {
    color: rgba(124, 124, 124, 1);
    text-decoration: none !important;

    &:hover {
      color: red;
    }

    &:active {
      color: red;
    }
  }
  .active-link a {
    color: red;
  }
}

@media screen and (min-width: 992px) {
  .desktop-content {
    display: block;
    // min-height: calc(100vh - 50px); // proveri ovo
    min-height: 100vh;
    .common-container {
      //  padding: 10px;
      height: calc(100vh - 40px);
      width: 80vw;
      margin-top: 40px;
      position: absolute;
      left: 10%;
      .logo {
        height: 90px;
        position: absolute;
        left: -62px;
        top: -24px;
        width: auto;
      }
      .common-center {
        height: calc(0.94 * (100vh - 40px - 44px - 10px));
        width: 60%;
        margin-top: 10px;
        // margin-left:10%;
      }
      .common-container > .div:last-child {
        margin-top: auto;
        // margin-bottom: 40px;
      }
      //  .bottom{
      //   max-width: 35vw;
      //   margin-top: 10px;
      //  }
    }
  }
}
@media screen and (max-width: 992px) {
  .mobile-content {
    display: block;
    // min-height: calc(100vh - 50px); // proveri ovo
    min-height: 100vh;
    .common-container {
      //  padding: 10px;
      height: calc(100vh - 40px);
      width: 89vw;
      margin-top: 20px;
      position: absolute;
      left: 5%;
    }
    .common-center {
      .tabela {
      }
    }
  }
  .header-mobile {
    .logo-mobile {
      float: left;
    }
    .nav-buttons-mobile {
      float: right;
      .icon {
        cursor: pointer;

        .menu-mobile-close-div {
          width: 50px;
          height: 50px;
          float: right;
        }
        .menu-mobile-open-div {
          width: 50px;
          height: 50px;
          float: right;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    .menu-mobile-open {
      background-color: #de0b1c;
      margin-top: 55px;
      position: absolute;
      width: 100%;
      z-index: 999;
      ul.menu-ul {
        // display: none;
        list-style: none;
        width: 100%;
        padding: 0;

        li {
          text-align: center;
          padding: 5px;
          //border-top: 1px solid #999;
          transition: all 0.3s ease;
          a {
            color: rgb(255, 255, 255) !important;
            text-decoration: none;
            font-family: "TcccUnityHeadLineStBold";
          }
        }
      }
    }

    li:hover {
      color: white;
    }
  }
}


.beforeStart{
  @media screen and (min-width: 992px) {
    background: url(../../public/before.jpeg) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: left top, right bottom;
  }

}

.mobile-home {
  @media screen and (max-width: 1280px) {
    // background: url(../../public/background-mobile.png) no-repeat center center
    //   fixed;
    background: url(../../public/background-mobile-posle.png) no-repeat center center;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // background-position: left top, right bottom;

    .custom-text {
      padding: 20px;
      font-family: "TcccUnityHeadLineStBold";
    }
    .common-center {
      color: white;
      margin-top: 16vh;
    }
  }
}

// @media screen and (min-width: 992px) {
//   .mobile-content {
//   }
// }
// @media screen and (min-width: 992px) {
//   .desktop-content {
//     display: block;
//     // min-height: calc(100vh - 50px); // proveri ovo
//     min-height: 100vh;

//     .common-container{
//      padding: 10px;
//      width: 80vw;
//      margin-top: 40px;
//      position: absolute;
//      left: 10%;
//       .logo {
//         height: 90px;
//         position: absolute;
//         left: -50px;
//         top: -16px;
//         width: auto;
//       }

//        .nav-button-desktop{
//         padding: 0 15px !important;
//         margin: 10px 0;
//        }

//        .nav-buttons {
//          background-color: white;
//          border-radius: 10px;

//          .border-right {
//            border-right: 1px solid black;
//            padding-top: 0 !important;
//            margin-top: 10px;

//            &:last-child{
//              border:0;
//            }
//          }

//          a {
//            color: black;
//            text-decoration: none !important;
//          }
//        }
//        .common-center{
//          max-height: 83vh;
//          // margin-top: 10%;
//          width:60%;
//        }
//     }
//   }
// }

.home {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (min-width: 2001px) {
    .header-text {
      font-size: 56px;
    }
  }

  @media screen and (max-width: 2200px) and (min-width: 1921px) {
    .header-text {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 1920px) and (min-width: 1601px) {
    .header-text {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1600px) and (min-width: 1441px) {
    .header-text {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 1367px) {
    .header-text {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1366px) and (min-width: 1281px) {
    .header-text {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 1280px) and (min-width: 1130px) {
    .header-text {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 1129px) {
    .header-text {
      font-size: 24px;
    }
  }

  /* Manja veličina fonta za manje ekrane */
  // @media screen and (max-width: 1402) {
  //   .header-text {
  //     font-size: 96px;
  //   }
  // }

  // @media screen and (min-height: 1401px) {
  //   .header-text {
  //     font-size: 90px;
  //   }
  // }

  // @media screen and (max-height: 1400px) and (min-height: 1301px) {
  //   .header-text {
  //     font-size: 82px;
  //   }
  // }

  // @media screen and (max-height: 1300px) and (min-height: 1201px) {
  //   .header-text {
  //     font-size: 74px;
  //   }
  // }

  // @media screen and (max-height: 1200px) and (min-height: 1101px) {
  //   .header-text {
  //     font-size: 66px;
  //   }
  // }

  // @media screen and (max-height: 1100px) and (min-height: 1001px) {
  //   .header-text {
  //     font-size: 58px;
  //   }
  // }

  // @media screen and (max-height: 1000px) and (min-height: 901px) {
  //   .header-text {
  //     font-size: 56px;
  //   }
  // }

  // /* Srednja veličina fonta za srednje ekrane */
  // @media screen and (max-height: 900px) and (min-height: 801px) {
  //   .header-text {
  //     font-size: 48px;
  //   }
  // }

  // /* Manja veličina fonta za manje ekrane */
  // @media screen and (max-height: 800px) and (min-height: 701px) {
  //   .header-text {
  //     font-size: 42px;
  //   }
  // }

  // @media screen and (max-height: 700px) and (min-height: 601px) {
  //   .header-text {
  //     font-size: 42px;
  //   }
  // }

  // @media screen and (max-height: 601px) {
  //   .header-text {
  //     font-size: 32px;
  //   }
  // }

  // @media screen and (min-width: 1660px) {
  //   .img-home-flasice-coca-cola-nagrade {
  //     width: 100%;
  //   }
  // }

  // @media screen and (min-width: 1420px) and (max-width: 1659px) {
  //   .img-home-flasice-coca-cola-nagrade {
  //     width: 68%;
  //   }
  // }

  // @media screen and (min-width: 1280px) and (max-width: 1419px) {
  //   .img-home-flasice-coca-cola-nagrade {
  //     width: 78%;
  //   }
  // }

  // /* Srednja veličina fonta za srednje ekrane */
  // @media screen and (min-width: 1130px) and (max-width: 1279px) {
  //   .img-home-flasice-coca-cola-nagrade {
  //     width: 80%;
  //   }
  // }

  // /* Manja veličina fonta za manje ekrane */
  // @media screen and (max-width: 1129px) {
  //   .img-home-flasice-coca-cola-nagrade {
  //     width: 80%;
  //   }
  // }

  .image-column img {
    width: 100%; /* Osigurava da slika zauzima dostupnu širinu */
  }

  @media screen and (max-height: 1000px) and (min-height: 901px) {
    .image-column img {
      max-width: 88%;
    }
  }

  @media screen and (max-height: 900px) and (min-height: 801px) {
    .image-column img {
      max-width: 78%;
    }
  }

  @media screen and (max-height: 800px) and (min-height: 751px) {
    .image-column img {
      max-width: 68%;
    }
  }

  @media screen and (max-height: 750px) and (min-height: 701px) {
    .image-column img {
      max-width: 62%;
    }
  }

  @media screen and (max-height: 700px) and (min-height: 651px) {
    .image-column img {
      max-width: 56%;
    }
  }

  @media screen and (max-height: 650px) and (min-height: 601px) {
    .image-column img {
      max-width: 52%;
    }
  }

  @media screen and (max-height: 601px) {
    .image-column img {
      max-width: 40%;
    }
  }

  .desktop-content {
    position: relative;

    .common-container {
      .common-center {
        // height: 85vh;
        .home-left-content {
          width: 92%;
          height: 98%;
          margin-left: 4%;
          // max-width: 48vw;

          .dynamic-container-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50vh;
            margin-left: -12%;
          }
          .header-text {
            // text-transform: uppercase;
            font-family: "TcccUnityHeadLineStBold";
            // font-weight: normal;
            color: white;
            text-align: center;
            // font-size: 40px;
          }
          .dynamic-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 48vh;
            margin-left: -20%;

            .image-column {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .img-home-flasice-coca-cola-nagrade {
            // width:60%;
            img {
              // max-height: 80vh;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      .bottom {
        max-width: 26vw;
        // margin-top: 2px;
        .bottom-content {
          max-width: 24vw;
          height: auto;
          // margin-left: 8% !important;
        }
      }

      // .bottom {
      //   width: 60%;
      //   margin-left: -10%;
      //   .bottom-content {
      //     height: 30%;
      //     width: 50%;
      //   }
      // }
      // .bottom{
      //   .bottom-content{
      //     max-width: 24vw;
      //     height:auto;
      //   }
      // }
    }
  }
}

.code-form {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-posle.png) no-repeat
      center center fixed;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .desktop-content {
    position: relative;
    .common-container {
      .right-header2 {
        position: absolute;
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        // height: 20vh;
        width: 100%;
        max-width: none;
        right: 0;
        .right-text-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.5;
        }

        .header-right-text-code-form {
          // font-size: 36px; /* Osnovna veličina fonta */
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }

        @media screen and (min-width: 2201px) {
          .header-right-text-code-form {
            font-size: 2.2em;
          }
        }

        @media screen and (max-width: 2200px) and (min-width: 1921px) {
          .header-right-text-code-form {
            font-size: 2.1em;
          }
        }

        @media screen and (max-width: 1920px) and (min-width: 1601px) {
          .header-right-text-code-form {
            font-size: 2em;
          }
        }

        @media screen and (max-width: 1600px) and (min-width: 1441px) {
          .header-right-text-code-form {
            font-size: 1.8em;
          }
        }

        @media screen and (max-width: 1440px) and (min-width: 1337px) {
          .header-right-text-code-form {
            font-size: 1.6em;
          }
        }

        @media screen and (max-width: 1336px) and (min-width: 1281px) {
          .header-right-text-code-form {
            font-size: 1.4em;
          }
        }

        @media screen and (max-width: 1280px) and (min-width: 1130px) {
          .header-right-text-code-form {
            font-size: 1.2em;
          }
        }

        @media screen and (max-width: 1129px) {
          .header-right-text-code-form {
            font-size: 1em;
          }
        }
      }
      .common-center {
        // height: 76vh;
        .dynamic-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 20vh;
          margin-left: -15%;
        }

        .dynamic-container-bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // height: 14vh;
          // margin-left: -15%;
        }

        .image-column {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .text-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.5;
        }
        .text-column-bottom {
          display: flex;
          justify-content: left;
          align-items: center;
          text-align: left;
          line-height: 1.5;
        }

        // .right-text-column {
        //   display: flex;
        //   justify-content: center;
        //   align-items: right;
        //   text-align: center;
        //   line-height: 1.5;
        // }

        // .header-right-text-code-form {
        //   font-size: 36px; /* Osnovna veličina fonta */
        //   text-transform: uppercase;
        //   font-family: "TcccUnityHeadLineStBold";
        //   // font-weight: normal;
        //   color: white;
        // }

        .header-text-code-form {
          // font-size: 36px; /* Osnovna veličina fonta */
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }

        .bottom-text-code-form {
          // text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }

        /* Medija upiti za dinamičku visinu i veličinu fonta */
        @media screen and (min-width: 1280px) {
          // .dynamic-container {
          //   height: 600px;
          // }
          .header-text-code-form {
            font-size: 1.7em;
          }
        }

        @media screen and (min-width: 1130px) and (max-width: 1279px) {
          // .dynamic-container {
          //   height: 500px;
          // }
          .header-text-code-form {
            font-size: 1.65em;
          }
        }

        @media screen and (max-width: 1129px) {
          // .dynamic-container {
          //   height: 400px;
          // }
          .header-text-code-form {
            font-size: 1.6em;
          }
        }

        /* Medija upiti za dinamičku visinu i veličinu fonta */
        @media screen and (min-width: 1280px) {
          // .dynamic-container {
          //   height: 600px;
          // }
          .bottom-text-code-form {
            font-size: 1.3em;
          }
        }

        @media screen and (min-width: 1130px) and (max-width: 1279px) {
          // .dynamic-container {
          //   height: 500px;
          // }
          .bottom-text-code-form {
            font-size: 1.25em;
          }
        }

        @media screen and (max-width: 1129px) {
          // .dynamic-container {
          //   height: 400px;
          // }
          .bottom-text-code-form {
            font-size: 1.2em;
          }
        }

        .red {
          margin-left: -34%;
          height: 61%;
          width: auto;
          margin-top: -3%;
          .privacy {
            font-size: 2.2vh;
          }
          .custom-label {
            display: block;
            text-align: center;
            font-size: 3.2vh;
            font-weight: bold;
            color: white;
          }
          .custom-input {
            width: 32vw;
            padding: 4px;
            font-size: 3.2vh;
            border: 1px solid #ccc;
            border-radius: 25px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            margin-top: 4px;
            text-align: center;
            height: 5vh;
            color: rgba(222, 11, 28, 1);
          }

          .custom-input::placeholder {
            color: #ccc; /* Podesi boju placeholder teksta prema potrebama */
            text-align: center; /* Centriraj placeholder tekst */
          }
          .form_input1 {
            width: 100%; /* Podesi širinu prema potrebama */
            max-width: 32vw; /* Maksimalna širina inputa */
            margin: 0 auto; /* Centriraj input */
          }
        }
        .uzmi-racun {
          width: 34%;
          height: 18%;
          /* background-size: contain; */
          background-repeat: no-repeat;
          background-position: center;
          // margin-left: 0%;
          // margin-top: -2vh;
        }
      }

      .bottom {
        max-width: 34vw;
        // margin-top: 2px;
        .bottom-content {
          max-width: 24vw;
          height: auto;
          // margin-left: 8% !important;
        }
      }

      // .bottom {
      //   width: 60%;
      //   margin-left: -9%;
      //   .bottom-content {
      //     height: 30%;
      //     width: 50%;
      //   }
      // }
      // .bottom{
      //   margin-top: 20px;
      // }
      // .bottom-content{
      //   width: 23vw;
      //   height: auto;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   margin-left: -10%;
      // }
    }
    button {
      &.sign-up {
        // display: inline-block;
        background-color: #fff; /* Pozadinska boja dugmeta */
        color: rgba(186, 186, 186, 1) !important; /* Boja teksta */
        padding: 2px 10px; /* Podesi padding prema potrebama */
        font-size: 2.2vh; /* Podesi veličinu fonta prema potrebama */
        text-align: center;
        text-decoration: none;
        border: 2px solid #fff; /* Podesi boju bordera prema potrebama */
        border-radius: 5px; /* Za zaobljene ivice */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Podesi senku prema potrebama */
        transition: background-color 0.3s, color 0.3s;
        margin-top: 10px;

        &-active,
        &:active,
        &:focus,
        &:hover,
        &:visited {
          background-color: #f1f1f1 !important;
          border-color: rgba(186, 186, 186, 1) !important;
        }
      }
    }
    .slazemse {
      text-decoration: none; /* Uklanja podvlačenje teksta */
      color: rgba(186, 186, 186, 1); /* Podesi boju teksta prema potrebama */
    }
  }

  .mobile-content {
    position: relative;
    .common-container {
      .common-center {
        padding-top: 70px;
        .dynamic-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 20vh;
          margin-left: -15%;
        }

        .dynamic-container-bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 20vh;
          // margin-left: -15%;
        }

        .image-column {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .text-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.5;
        }

        .header-text-code-form {
          // font-size: 36px; /* Osnovna veličina fonta */
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }

        .red {
          // margin-left: -34%;
          // height: 61%;
          // width: auto;
          // margin-top: -3%;
          .privacy {
            font-size: 2.2vh;
          }
          .custom-label {
            display: block;
            text-align: center;
            font-size: 2.2vh;
            font-weight: bold;
            color: white;
          }
          .custom-input {
            width: 70vw;
            padding: 4px;
            font-size: 2.2vh;
            border: 1px solid #ccc;
            border-radius: 25px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            margin-top: 4px;
            text-align: center;
            height: 3vh;
            color: rgba(222, 11, 28, 1);
          }

          .custom-input::placeholder {
            color: #ccc; /* Podesi boju placeholder teksta prema potrebama */
            text-align: center; /* Centriraj placeholder tekst */
          }
          .form_input1 {
            width: 100%; /* Podesi širinu prema potrebama */
            max-width: 32vw; /* Maksimalna širina inputa */
            margin: 0 auto; /* Centriraj input */
          }
        }
        .uzmi-racun {
          width: 34%;
          height: 18%;
          /* background-size: contain; */
          background-repeat: no-repeat;
          background-position: center;
          // margin-left: 0%;
          // margin-top: -2vh;
        }
      }

      .bottom {
        width: 60%;
        margin-left: -9%;
        .bottom-content {
          height: 30%;
          width: 50%;
        }
      }
      // .bottom{
      //   margin-top: 20px;
      // }
      // .bottom-content{
      //   width: 23vw;
      //   height: auto;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   margin-left: -10%;
      // }
    }
    button {
      &.sign-up {
        // display: inline-block;
        background-color: #fff; /* Pozadinska boja dugmeta */
        color: rgba(186, 186, 186, 1) !important; /* Boja teksta */
        padding: 2px 10px; /* Podesi padding prema potrebama */
        font-size: 2.2vh; /* Podesi veličinu fonta prema potrebama */
        text-align: center;
        text-decoration: none;
        border: 2px solid #fff; /* Podesi boju bordera prema potrebama */
        border-radius: 5px; /* Za zaobljene ivice */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Podesi senku prema potrebama */
        transition: background-color 0.3s, color 0.3s;
        margin-top: 10px;

        &-active,
        &:active,
        &:focus,
        &:hover,
        &:visited {
          background-color: #f1f1f1 !important;
          border-color: rgba(186, 186, 186, 1) !important;
        }
      }
    }
    .slazemse {
      text-decoration: none; /* Uklanja podvlačenje teksta */
      color: rgba(186, 186, 186, 1); /* Podesi boju teksta prema potrebama */
    }
  }
}

.mechanism {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md2.png) no-repeat center
      center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-posle.png) no-repeat
      center center fixed;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  @media screen and (min-width: 2001px) {
    .header-text {
      font-size: 86px;
      .mechanism-img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 2200px) and (min-width: 1921px) {
    .header-text {
      font-size: 76px;
    }
    .mechanism-img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1920px) and (min-width: 1601px) {
    .header-text {
      font-size: 56px;
    }
    .mechanism-img {
      width: 79%;
      // height: 100%;
    }
  }

  @media screen and (max-width: 1600px) and (min-width: 1441px) {
    .header-text {
      font-size: 50px;
    }
    .mechanism-img {
      width: 75%;
    }
  }

  @media screen and (max-width: 1440px) and (min-width: 1367px) {
    .header-text {
      font-size: 46px;
    }
    .mechanism-img {
      width: 72%;
    }
  }

  @media screen and (max-width: 1366px) and (min-width: 1281px) {
    .header-text {
      font-size: 42px;
    }
    .mechanism-img {
      width: 69%;
    }
  }

  @media screen and (max-width: 1280px) and (min-width: 1130px) {
    .header-text {
      font-size: 38px;
    }
    .mechanism-img {
      width: 72%;
    }
  }

  @media screen and (max-width: 1129px) {
    .header-text {
      font-size: 32px;
    }
    .mechanism-img {
      width: 70%;
    }
  }

  // @media screen and (min-width: 1660px) {
  //   .mechanism-img {
  //     width: 70%;
  //   }
  // }

  // @media screen and (min-width: 1420px) and (max-width: 1659px) {
  //   .mechanism-img {
  //     width: 80%;
  //   }
  // }

  // @media screen and (min-width: 1280px) and (max-width: 1419px) {
  //   .mechanism-img {
  //     width: 90%;
  //   }
  // }

  /* Srednja veličina fonta za srednje ekrane */
  // @media screen and (min-width: 1130px) and (max-width: 1279px) {
  //   .mechanism-img {
  //     width: 85%;
  //   }
  // }

  // /* Manja veličina fonta za manje ekrane */
  // @media screen and (max-width: 1129px) {
  //   .mechanism-img {
  //     width: 85%;
  //   }
  // }

  .desktop-content {
    .common-container {
      .common-center {
        .mechanism-left-content {
          width: 92%;
          height: 98%;
          margin-left: 4%;
          // max-width: 48vw;
          .header-text {
            text-transform: uppercase;
            font-family: "TcccUnityHeadLineStBold";
            // font-weight: normal;
            color: white;
            text-align: left;
            // font-size: 40px;
          }
          .mechanism-img {
            // width:60%;
            text-align: left;
            img {
              // max-height: 80vh;
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .bottom {
        width: 62%;
        margin-left: -13%;
        .bottom-content {
          height: 30%;
          width: 50%;
        }
      }
    }
  }

  .mobile-content {
  }
}

.awards {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-nagrade-posle.png) no-repeat center
      center fixed;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .desktop-content {
    position: relative;

    .common-container {
      .right-header2 {
        position: absolute;
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        // height: 20vh;
        width: 100%;
        max-width: none;
        right: 0;
        .right-text-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.5;
        }

        .header-right-text-code-form {
          // font-size: 36px; /* Osnovna veličina fonta */
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }


        @media screen and (min-width: 2201px) {
          .header-right-text-code-form {
            font-size: 2.2em;
          }
        }

        @media screen and (max-width: 2200px) and (min-width: 1921px) {
          .header-right-text-code-form {
            font-size: 2.1em;
          }
        }

        @media screen and (max-width: 1920px) and (min-width: 1601px) {
          .header-right-text-code-form {
            font-size: 1.9em;
          }
        }

        @media screen and (max-width: 1600px) and (min-width: 1441px) {
          .header-right-text-code-form {
            font-size: 1.7em;
          }
        }

        @media screen and (max-width: 1440px) and (min-width: 1367px) {
          .header-right-text-code-form {
            font-size: 1.5em;
          }
        }

        @media screen and (max-width: 1366px) and (min-width: 1281px) {
          .header-right-text-code-form {
            font-size: 1.3em;
          }
        }

        @media screen and (max-width: 1280px) and (min-width: 1130px) {
          .header-right-text-code-form  {
            font-size: 1.1em;
          }
        }

        @media screen and (max-width: 1129px) {
          .header-right-text-code-form {
            font-size: 1em;
          }
        }
      }
      .common-center {
        margin-top: 0;
        .dynamic-container {
          display: flex;
          // flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 13vh;
          margin-left: -15%;

          .image-column {
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: 1921px) {
              img {
                width: 100%;
              }
            }
            @media screen and (max-width: 1920px) and (min-width: 1601px) {
              img {
                width: 100%;
              }
            }
            @media screen and (max-width: 1600px) and (min-width: 1441px) {
              img {
                width: 95%;
              }
            }
            @media screen and (max-width: 1440px) and (min-width: 1367px) {
              img {
                width: 90%;
              }
            }

            @media screen and (max-width: 1366px) and (min-width: 1281px) {
              img {
                width: 78%;
              }
            }

            @media screen and (max-width: 1280px) and (min-width: 1130px) {
              img {
                width: 74%;
              }
            }

            @media screen and (max-width: 1129px) {
              img {
                width: 70%;
              }
            }
          }

          .text-column {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1.5;


            .header-text-awards {
              text-transform: uppercase;
              font-family: "TcccUnityHeadLineStBold";
              color: white;
            }

            @media screen and (max-width: 2200px) and (min-width: 1921px) {
              .header-text-awards {
                font-size: 2.2em;
              }
            }

            @media screen and (max-width: 1920px) and (min-width: 1601px) {
              .header-text-awards {
                font-size: 1.9em;
              }
            }

            @media screen and (max-width: 1600px) and (min-width: 1441px) {
              .header-text-awards {
                font-size: 1.7em;
              }
            }

            @media screen and (max-width: 1440px) and (min-width: 1367px) {
              .header-text-awards {
                font-size: 1.5em;
              }
            }

            @media screen and (max-width: 1366px) and (min-width: 1281px) {
              .header-text-awards {
                font-size: 1.3em;
              }
            }

            @media screen and (max-width: 1280px) and (min-width: 1130px) {
              .header-text-awards {
                font-size: 1.2em;
              }
            }

            @media screen and (max-width: 1129px) {
              .header-text-awards {
                font-size: 1.1em;
              }
            }

            @media screen and (min-width: 1921px) {
              .header-text-awards-bottom {
                font-size: 1.2em;
              }
            }

            @media screen and (max-width: 1920px) and (min-width: 1601px) {
              .header-text-awards-bottom {
                font-size: 1em;
              }
            }

            @media screen and (max-width: 1600px) and (min-width: 1441px) {
              .header-text-awards-bottom {
                font-size: 0.8em;
              }
            }

            @media screen and (max-width: 1440px) and (min-width: 1367px) {
              .header-text-awards-bottom {
                font-size: 0.7em;
              }
            }

            @media screen and (max-width: 1366px) and (min-width: 1281px) {
              .header-text-awards-bottom {
                font-size: 0.6em;
              }
            }

            @media screen and (max-width: 1280px) and (min-width: 1130px) {
              .header-text-awards-bottom {
                font-size: 0.5em;
              }
            }

            @media screen and (max-width: 1129px) {
              .header-text-awards-bottom {
                font-size: 0.4em;
              }
            }

            // @media screen and (max-width: 1366px) and (min-width: 1281px) and (max-height: 900px) and (min-height: 768px) {
            //   .header-text-awards-bottom {
            //     font-size: 0.6em;
            //     margin-top: -16%;
            //   }
            // }

            .header-text-awards-bottom {
              // text-transform: uppercase;
              font-family: "TcccUnityHeadLineStBold";
              color: white;
              // font-size: 1em;
            }
          }
        }

        // .dynamic-container-align {
        //   align-items: end;
        // }
      }
      .bottom {
        max-width: 35vw;
        margin-top: 2px;
        .bottom-content {
          max-width: 24vw;
          height: auto;
          // margin-left: 8% !important;
        }
      }

      // .bottom {
      //   width: 60%;
      //   margin-left: -8%;
      //   .bottom-content {
      //     height: 30%;
      //     width: 50%;
      //   }
      // }
    }
  }

  .mobile-content {
  }
}

.winners {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-posle.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .desktop-content {
    position: relative;

    .awards-button {
      position: absolute;
      top: 2%; /* Pozicija od vrha, prilagodite prema potrebi */
      left: 26%; /* Pozicija od leva, prilagodite prema potrebi */
      transform: translateX(-50%); /* Centriranje dugmeta */
      padding: 1px 20px;
      background-color: transparent; /* Transparentna pozadina */
      color: #ff0000; /* Boja teksta */
      border: none;
      border-radius: 5px;
      font-size: 1.5vh;
      font-weight: bold;
      height: 8%;
      width: 24%;
      cursor: pointer;
      transition: box-shadow 0.3s ease; /* Animacija za senku */
      &-active,
      &:active,
      &:focus,
      &:hover,
      &:visited {
        background-color: #ff0000 !important;
        border-color: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: white;
      }
    }

    .awards-button2 {
      position: absolute;
      top: 2%; /* Pozicija od vrha, prilagodite prema potrebi */
      left: 72%; /* Pozicija od leva, prilagodite prema potrebi */
      transform: translateX(-50%); /* Centriranje dugmeta */
      padding: 1px 20px;
      background-color: transparent; /* Transparentna pozadina */
      color: #ff0000; /* Boja teksta */
      border: none;
      border-radius: 5px;
      font-size: 1.5vh;
      font-weight: bold;
      height: 8%;
      width: 24%;
      cursor: pointer;
      transition: box-shadow 0.3s ease; /* Animacija za senku */
      &-active,
      &:active,
      &:focus,
      &:hover,
      &:visited {
        background-color: #ff0000 !important;
        border-color: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: white;
      }
    }

    .common-container {
      .common-center {
        // height: 78vh;

        @media screen and (min-width: 2001px) {
          .header-text-winners {
            font-size: 86px;
          }
        }

        @media screen and (max-width: 2200px) and (min-width: 1921px) {
          .header-text-winners {
            font-size: 76px;
          }
        }

        @media screen and (max-width: 1920px) and (min-width: 1601px) {
          .header-text-winners {
            font-size: 56px;
          }
        }
        @media screen and (max-width: 1600px) and (min-width: 1441px) {
          .header-text-winners {
            font-size: 50px;
          }
        }

        @media screen and (max-width: 1440px) and (min-width: 1337px) {
          .header-text-winners {
            font-size: 46px;
          }
        }

        @media screen and (max-width: 1336px) and (min-width: 1281px) {
          .header-text-winners {
            font-size: 40px;
          }
        }
        @media screen and (max-width: 1280px) and (min-width: 1130px) {
          .header-text-winners {
            font-size: 38px;
          }
        }
        @media screen and (max-width: 1129px) {
          .header-text-winners {
            font-size: 32px;
          }
        }
        .header-text-winners {
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
          text-align: left;
          font-size: 3em;
        }

        // @media screen and (min-width: 1280px) {
        //   .header-text-winners {
        //     font-size: 56px;
        //   }
        // }

        // /* Srednja veličina fonta za srednje ekrane */
        // @media screen and (min-width: 1130px) and (max-width: 1279px) {
        //   .header-text-winners {
        //     font-size: 48px;
        //   }
        // }

        // /* Manja veličina fonta za manje ekrane */
        // @media screen and (max-width: 1129px) {
        //   .header-text-winners {
        //     font-size: 42px;
        //   }
        // }
        // .tekst-uzivaj{
        //   width: 40vw;
        //   height: auto;
        //   background-position: center;
        //   background-repeat: no-repeat;
        //   margin-top: 2%;
        //   margin-left: -19%;
        // }
        .tabela {
          position: absolute;
          left: 22%;
          transform: translateX(-50%);
          width: 44%;
          height: 50%;
          img {
            width: 100%;
            height: 100%;
          }

          .date-picker-container,
          .week-dropdown-container {
            position: absolute;
            top: 12%; /* Prilagodite prema potrebi */
            left: 50%;
            transform: translateX(-50%);
            width: 74%; /* Prilagodite prema potrebi */
            text-align: center;
            height: 81%;
          }
          // .date_picker .SingleDatePicker,
          // .date_picker .SingleDatePickerInput,
          // .date_picker .SingleDatePicker_picker {
          //   width: 80% !important; /* Primer za postavljanje širine kalendara */
          //   background-color: none !important;
          // }

          .date_picker .SingleDatePicker_picker {
            transform: scale(0.8); /* Primer za skaliranje veličine kalendara */
            transform-origin: top left;
          }

          // .date_picker .CalendarMonth,
          // .date_picker .CalendarMonth_caption {
          //   font-size: 2vh; /* Primer za promenu veličine fonta u kalendaru */
          // }

          .table_page_content {
            // max-height: 50vh; /* Visina tabele */
            height: 90%;
            overflow-y: auto; /* Omogućava skrolovanje */
          }

          .table_div {
            max-height: 100%;
          }
          // .tabela-dobitnici2{
          //   color: white;
          //   font-family: "TcccUnityHeadLineStBold";
          //   /* text-align: left;
          // }
          .date-picker-container input {
            background-color: transparent; /* Transparentna pozadina */
            color: #ff0000; /* Boja teksta */
            border: 2px solid #ff0000; /* Bordura u boji */
            border-radius: 5px; /* Zaobljeni uglovi */
            padding: 3px 10px; /* Unutrašnji razmak */
            font-size: 12px; /* Veličina fonta */
            font-weight: bold; /* Bold tekst */
            text-align: center; /* Centriranje teksta */
            outline: none; /* Uklanjanje outline */
            transition: box-shadow 0.3s ease, background-color 0.3s ease,
              color 0.3s ease; /* Animacija za senku i boju teksta */
          }
          .week-dropdown-container select {
            color: #ff0000; /* Boja teksta */
            border: 2px solid #ff0000; /* Bordura u boji */
            border-radius: 5px; /* Zaobljeni uglovi */
            padding: 3px 10px; /* Unutrašnji razmak */
            font-size: 12px; /* Veličina fonta */
            font-weight: bold; /* Bold tekst */
            text-align: center; /* Centriranje teksta */
            outline: none; /* Uklanjanje outline */
            transition: box-shadow 0.3s ease, background-color 0.3s ease,
              color 0.3s ease;
          }

          .date-picker-container input:focus,
          .date-picker-container input:hover,
          .week-dropdown-container select:focus,
          .week-dropdown-container select:hover {
            background-color: #ff0000; /* Crvena pozadina pri hoveru */
            color: #ffffff; /* Bela boja teksta pri hoveru */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Senka pri hoveru */
          }

          .week-dropdown-container select {
            appearance: none; /* Uklanjanje defaultnog stila za padajući meni */
            -webkit-appearance: none; /* Uklanjanje defaultnog stila za Safari */
            -moz-appearance: none; /* Uklanjanje defaultnog stila za Firefox */
            position: relative;
            background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="%23ff0000" d="M0 2l4 4 4-4H0z"/></svg>');
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px;
            padding-right: 30px; /* Prostor za ikonicu */
          }
        }
      }
      .bottom {
        max-width: 35vw;
        margin-top: 2px;
        .bottom-content {
          max-width: 24vw;
          height: auto;
          // margin-left: 8% !important;
        }
      }
    }
  }
}

.mobile-winners {
  @media screen and (max-width: 1280px) {
    // background: url(../../public/background-mobile.png) no-repeat center center
    //   fixed;
    background: url(../../public/background-mobile-posle.png) no-repeat center center;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // background-position: left top, right bottom;
  }

  .common-center {
    margin-top: 16vh;

    .tabela {
      position: absolute;
      width: 100%;
      img {
        width: 100%;
      }

      .date-picker-container,
      .week-dropdown-container {
        position: absolute;
        top: 12%; /* Prilagodite prema potrebi */
        left: 50%;
        transform: translateX(-50%);
        width: 74%; /* Prilagodite prema potrebi */
        text-align: center;
        height: 81%;
      }
      // .date_picker .SingleDatePicker,
      // .date_picker .SingleDatePickerInput,
      // .date_picker .SingleDatePicker_picker {
      //   width: 80% !important; /* Primer za postavljanje širine kalendara */
      //   background-color: none !important;
      // }

      .date_picker .SingleDatePicker_picker {
        transform: scale(0.8); /* Primer za skaliranje veličine kalendara */
        transform-origin: top left;
      }

      // .date_picker .CalendarMonth,
      // .date_picker .CalendarMonth_caption {
      //   font-size: 2vh; /* Primer za promenu veličine fonta u kalendaru */
      // }

      .table_page_content {
        // max-height: 50vh; /* Visina tabele */
        height: 92%;
        overflow-y: auto; /* Omogućava skrolovanje */
      }
      .table_page_content-mobile {
        // max-height: 50vh; /* Visina tabele */
        height: 86%;
        overflow-y: auto; /* Omogućava skrolovanje */
      }

      .table_div {
        max-height: 96%;
      }
      .table_div-mobile {
        max-height: 100%;
      }
      .date-picker-container input {
        background-color: transparent; /* Transparentna pozadina */
        color: #ff0000; /* Boja teksta */
        border: 2px solid #ff0000; /* Bordura u boji */
        border-radius: 5px; /* Zaobljeni uglovi */
        padding: 3px 10px; /* Unutrašnji razmak */
        font-size: 12px; /* Veličina fonta */
        font-weight: bold; /* Bold tekst */
        text-align: center; /* Centriranje teksta */
        outline: none; /* Uklanjanje outline */
        transition: box-shadow 0.3s ease, background-color 0.3s ease,
          color 0.3s ease; /* Animacija za senku i boju teksta */
      }
      .week-dropdown-container select {
        color: #ff0000; /* Boja teksta */
        border: 2px solid #ff0000; /* Bordura u boji */
        border-radius: 5px; /* Zaobljeni uglovi */
        padding: 3px 10px; /* Unutrašnji razmak */
        font-size: 12px; /* Veličina fonta */
        font-weight: bold; /* Bold tekst */
        text-align: center; /* Centriranje teksta */
        outline: none; /* Uklanjanje outline */
        transition: box-shadow 0.3s ease, background-color 0.3s ease,
          color 0.3s ease;
      }

      .date-picker-container input:focus,
      .date-picker-container input:hover,
      .week-dropdown-container select:focus,
      .week-dropdown-container select:hover {
        background-color: #ff0000; /* Crvena pozadina pri hoveru */
        color: #ffffff; /* Bela boja teksta pri hoveru */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Senka pri hoveru */
      }

      .week-dropdown-container select {
        appearance: none; /* Uklanjanje defaultnog stila za padajući meni */
        -webkit-appearance: none; /* Uklanjanje defaultnog stila za Safari */
        -moz-appearance: none; /* Uklanjanje defaultnog stila za Firefox */
        position: relative;
        background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="%23ff0000" d="M0 2l4 4 4-4H0z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 12px;
        padding-right: 30px; /* Prostor za ikonicu */
      }
    }
  }
  .awards-button {
    position: absolute;
    top: 2%; /* Pozicija od vrha, prilagodite prema potrebi */
    left: 26%; /* Pozicija od leva, prilagodite prema potrebi */
    transform: translateX(-50%); /* Centriranje dugmeta */
    padding: 1px 20px;
    background-color: transparent; /* Transparentna pozadina */
    color: #ff0000; /* Boja teksta */
    border: none;
    border-radius: 5px;
    font-size: 1.2vh;
    font-weight: bold;
    height: 8%;
    width: 26%;
    cursor: pointer;
    transition: box-shadow 0.3s ease; /* Animacija za senku */
    &-active,
    &:active,
    &:focus,
    &:hover,
    &:visited {
      background-color: #ff0000 !important;
      border-color: 0 4px 8px rgba(0, 0, 0, 0.2);
      color: white;
    }
  }

  .awards-button2 {
    position: absolute;
    top: 2%; /* Pozicija od vrha, prilagodite prema potrebi */
    left: 72%; /* Pozicija od leva, prilagodite prema potrebi */
    transform: translateX(-50%); /* Centriranje dugmeta */
    padding: 1px 20px;
    background-color: transparent; /* Transparentna pozadina */
    color: #ff0000; /* Boja teksta */
    border: none;
    border-radius: 5px;
    font-size: 1.2vh;
    font-weight: bold;
    height: 8%;
    width: 24%;
    cursor: pointer;
    transition: box-shadow 0.3s ease; /* Animacija za senku */
    &-active,
    &:active,
    &:focus,
    &:hover,
    &:visited {
      background-color: #ff0000 !important;
      border-color: 0 4px 8px rgba(0, 0, 0, 0.2);
      color: white;
    }
  }
}

.rules {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-posle.png) no-repeat center center
      fixed;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .desktop-content {
    position: relative;

    .common-container {
      .custom-content {
        // background-color: #ff0000; /* Crvena boja pozadine */
        padding: 30px; /* Podesi padding prema potrebama */
        border-radius: 10px; /* Zaobljene ivice */
        text-align: center; /* Centriraj tekst */
      }

      .custom-text {
        color: #ffffff; /* Bela boja teksta */
        font-size: 3vh; /* Podesi veličinu fonta prema potrebama */
        line-height: 1.5; /* Podesi razmak između redova */
        margin-bottom: 10px; /* Podesi donji razmak */
      }

      .button-container {
        display: flex;
        justify-content: center; /* Centriraj dugme horizontalno */
        margin-top: 20px; /* Podesi gornji razmak prema potrebama */
      }

      .custom-button {
        background-color: #ffffff; /* Bela boja pozadine dugmeta */
        color: #000000; /* Crna boja teksta */
        padding: 10px 20px; /* Podesi padding prema potrebama */
        font-size: 16px; /* Podesi veličinu fonta prema potrebama */
        border: none; /* Bez bordera */
        border-radius: 25px; /* Zaobljene ivice */
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Blaga senka */
        cursor: pointer; /* Promena kursora na pointer */
        transition: background-color 0.3s ease, color 0.3s ease; /* Glatke tranzicije */
      }

      .custom-button:hover {
        background-color: #f1f1f1; /* Boja pozadine pri hoveru */
        color: #000000; /* Boja teksta pri hoveru */
      }

      .button-text {
        text-decoration: none; /* Bez podvlačenja teksta */
        color: inherit; /* Nasleđivanje boje teksta */
      }

      .common-center {
        // margin-top: 10%;
        margin-left: -9%;
        // height: 65vh;
        .ultra-bg {
          background-color: #ff0000; /* Boja pozadine u skladu sa sajtom */
          padding: 30px; /* Podesi padding prema potrebama */
        }

        .text-white-small {
          color: #ffffff; /* Boja teksta bela */
          font-size: 14px; /* Podesi veličinu fonta prema potrebama */
          text-align: center; /* Centriraj tekst */
        }

        .text-black-small {
          color: #000000; /* Boja teksta crna */
          font-size: 14px; /* Podesi veličinu fonta prema potrebama */
          text-align: center; /* Centriraj tekst */
        }
      }
      .bottom {
        width: 60%;
        margin-left: -9%;
        .bottom-content {
          height: 30%;
          width: 50%;
        }
      }
      // .bottom{
      //   max-width: 35vw;
      //   // margin-top: 45vh;
      //   .bottom-content{
      //     max-width: 24vw;
      //     height:auto;
      //   }
      // }
      // .bottom-content{
      //   width: 23vw;
      //   height: auto;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   /* background-size: 100% 100%; */
      //   margin-left: -58%;
      //   // margin-top: 37%;
      // }
    }
  }
  .mobile-content {
    .custom-text {
      padding: 20px;
      font-family: "TcccUnityHeadLineStBold";
    }
    .common-center {
      color: white;
      margin-top: 16vh;
    }
    button {
      background-color: #ffffff; /* Bela boja pozadine dugmeta */
      color: #000000; /* Crna boja teksta */
      padding: 10px 20px; /* Podesi padding prema potrebama */
      font-size: 16px; /* Podesi veličinu fonta prema potrebama */
      border: none; /* Bez bordera */
      border-radius: 25px; /* Zaobljene ivice */
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Blaga senka */
      cursor: pointer; /* Promena kursora na pointer */
      transition: background-color 0.3s ease, color 0.3s ease; /* Glatke tranzicije */
    }
  }
}

.contact {
  @media screen and (min-width: 1280px) {
    background: url(../../public/background-high-lg.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position: left top, right bottom;
  }
  @media screen and (min-width: 1130px) and (max-width: 1279px) {
    background: url(../../public/background-high-md.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 1129px) {
    background: url(../../public/background-high-sm.png) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: 992px) {
    background: url(../../public/background-mobile-posle-contact.png) no-repeat center
      center fixed;
    background-color: #de0b1c;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .desktop-content {
    position: relative;

    .common-container {
      .right-header2 {
        position: absolute;
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        // height: 20vh;
        width: 100%;
        max-width: none;
        right: 0;
        .right-text-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: 1.5;
        }

        .header-right-text-code-form {
          // font-size: 36px; /* Osnovna veličina fonta */
          text-transform: uppercase;
          font-family: "TcccUnityHeadLineStBold";
          // font-weight: normal;
          color: white;
        }

        @media screen and (min-width: 2201px) {
          .header-right-text-code-form {
            font-size: 2.2em;
          }
        }

        @media screen and (max-width: 2200px) and (min-width: 1921px) {
          .header-right-text-code-form {
            font-size: 2.1em;
          }
        }

        @media screen and (max-width: 1920px) and (min-width: 1601px) {
          .header-right-text-code-form {
            font-size: 2em;
          }
        }

        @media screen and (max-width: 1600px) and (min-width: 1441px) {
          .header-right-text-code-form {
            font-size: 1.8em;
          }
        }

        @media screen and (max-width: 1440px) and (min-width: 1337px) {
          .header-right-text-code-form {
            font-size: 1.6em;
          }
        }

        @media screen and (max-width: 1336px) and (min-width: 1281px) {
          .header-right-text-code-form {
            font-size: 1.4em;
          }
        }

        @media screen and (max-width: 1280px) and (min-width: 1130px) {
          .header-right-text-code-form {
            font-size: 1.2em;
          }
        }

        @media screen and (max-width: 1129px) {
          .header-right-text-code-form {
            font-size: 1em;
          }
        }


        // @media screen and (min-width: 1130px) and (max-width: 1279px) {
        //   // .dynamic-container {
        //   //   height: 500px;
        //   // }
        //   .header-right-text-code-form {
        //     font-size: 1.65em;
        //   }
        // }

        // @media screen and (max-width: 1129px) {
        //   // .dynamic-container {
        //   //   height: 400px;
        //   // }
        //   .header-right-text-code-form {
        //     font-size: 1.6em;
        //   }
        // }
      }
      .common-center {
        // height: 78vh;

        // @media screen and (min-width: 2201px) {
        //   .dynamic-container {
        //     height: 28vh;
        //   }
        // }

        // @media screen and (max-width: 2200px) and (min-width: 1921px) {
        //   .dynamic-container {
        //     height: 26vh;
        //   }
        // }

        // @media screen and (max-width: 1920px) and (min-width: 1601px) {
        //   .dynamic-container {
        //     height: 24vh;
        //   }
        // }

        // @media screen and (max-width: 1600px) and (min-width: 1441px) {
        //   .dynamic-container {
        //     height: 24vh;
        //   }
        // }

        // @media screen and (max-width: 1440px) and (min-width: 1337px) {
        //   .dynamic-container {
        //     height: 25vh;
        //   }
        // }

        // @media screen and (max-width: 1336px) and (min-width: 1281px) {
        //   .dynamic-container {
        //     height: 22vh;
        //   }
        // }

        // @media screen and (max-width: 1280px) and (min-width: 1130px) {
        //   .dynamic-container {
        //     height: 20vh;
        //   }
        // }

        // @media screen and (max-width: 1129px) {
        //   .dynamic-container {
        //     height: 20vh;
        //   }
        // }

        // @media screen and (min-width: 2201px) {
        //   .dynamic-container-email {
        //     height: 20vh;
        //   }
        // }

        // @media screen and (max-width: 2200px) and (min-width: 1921px) {
        //   .dynamic-container-email {
        //     height: 16vh;
        //   }
        // }

        // @media screen and (max-width: 1920px) and (min-width: 1601px) {
        //   .dynamic-container-email {
        //     height: 16vh;
        //   }
        // }

        // @media screen and (max-width: 1600px) and (min-width: 1441px) {
        //   .dynamic-container-email {
        //     height: 15vh;
        //   }
        // }

        // @media screen and (max-width: 1440px) and (min-width: 1337px) {
        //   .dynamic-container-email {
        //     height: 14vh;
        //   }
        // }

        // @media screen and (max-width: 1336px) and (min-width: 1281px) {
        //   .dynamic-container-email {
        //     height: 13vh;
        //   }
        // }

        // @media screen and (max-width: 1280px) and (min-width: 1130px) {
        //   .dynamic-container-email {
        //     height: 12vh;
        //   }
        // }

        // @media screen and (max-width: 1129px) {
        //   .dynamic-container-email {
        //     height: 12vh;
        //   }
        // }

        .dynamic-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // height: 24vh;
          margin-left: -15%;
          height: 20vh;

          .text-column {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1.5;

            // .image-column {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            // }

            .header-text-contact {
              text-transform: uppercase;
              font-family: "TcccUnityHeadLineStBold";
              color: white;
            }
            .header-text-contact-viber {
              // text-transform: uppercase;
              font-family: "TcccUnityHeadLineStBold";
              color: white;
              font-size: 0.4em;
            }

            @media screen and (min-width: 2201px) {
              .header-text-contact {
                font-size: 3em;
              }
            }

            @media screen and (max-width: 2200px) and (min-width: 1921px) {
              .header-text-contact {
                font-size: 2.2em;
              }
            }

            @media screen and (max-width: 1920px) and (min-width: 1601px) {
              .header-text-contact {
                font-size: 2em;
              }
            }

            @media screen and (max-width: 1600px) and (min-width: 1441px) {
              .header-text-contact {
                font-size: 1.8em;
              }
            }

            @media screen and (max-width: 1440px) and (min-width: 1337px) {
              .header-text-contact {
                font-size: 1.6em;
              }
            }

            @media screen and (max-width: 1336px) and (min-width: 1281px) {
              .header-text-contact {
                font-size: 1.4em;
              }
            }

            @media screen and (max-width: 1280px) and (min-width: 1130px) {
              .header-text-contact {
                font-size: 1.3em;
              }
            }

            @media screen and (max-width: 1129px) {
              .header-text-contact {
                font-size: 1.2em;
              }
            }
          }
          .image-column {
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: 1921px) {
              img {
                width: 100%;
              }
            }
            @media screen and (max-width: 1920px) and (min-width: 1601px) {
              img {
                width: 100%;
              }
            }
            @media screen and (max-width: 1600px) and (min-width: 1441px) {
              img {
                width: 95%;
              }
            }
            @media screen and (max-width: 1440px) and (min-width: 1367px) {
              img {
                width: 90%;
              }
            }

            @media screen and (max-width: 1366px) and (min-width: 1281px) {
              img {
                width: 80%;
              }
            }

            @media screen and (max-width: 1280px) and (min-width: 1130px) {
              img {
                width: 80%;
              }
            }

            @media screen and (max-width: 1129px) {
              img {
                width: 70%;
              }
            }
          }
        }

        .dynamic-container-email {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // height: 24vh;
          margin-left: -15%;

          .text-column {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1.5;

            .header-text-contact-email {
              text-transform: none;
              font-family: "TcccUnityHeadLineStBold";
              color: white;
              font-size: 0.8em;
            }
          }
        }
        .call-centar {
          // max-width: 50vw;
          // margin-left: -15%;
          // width: 70%;
          // height: 90%;
          height: 80%;
          width: 60%;
          margin-left: 5%;
          img {
            // max-height: 80vh;
            //     margin-top: 2vh;
            height: 100%;
            width: 100%;
            margin-top: 10%;
          }
        }
        .tekst-desno2 {
          position: absolute;
          width: 32%;
          height: 12%;
          background-repeat: no-repeat;
          background-position: center;
          margin-left: 68%;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .bottom {
        max-width: 35vw;
        // margin-top: 20vh;
        .bottom-content {
          max-width: 24vw;
          height: auto;
        }
      }
    }
  }
}
