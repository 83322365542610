@font-face {
  font-family: 'TcccUnityHeadLineStBlack';
  src: url('./fonts/TCCC\ UnityHeadline\ ST\ Black.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TcccUnityHeadLineStBold';
  src: url('./fonts/TCCCUnityHeadlineSTBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TcccUnityHeadLineStLight';
  src: url('./fonts/TCCC\ UnityHeadline\ ST\ Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TcccUnityHeadLineStMedium';
  src: url('./fonts/TCCC\ UnityHeadline\ ST\ Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TcccUnityHeadLineStRegular';
  src: url('./fonts/TCCC\ UnityHeadline\ ST\ Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}