.popUp-bg {
  background-color: #e13011;
  border: 10px solid white;
  border-radius: 20px;
}

.popUp-btn {
  background-color: transparent;
  border: 0;
  outline: none;
}

.popUp-text {
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 30px;
  padding-top: 0;
  font-size: 22px;
  line-height: 1.2;
}

.popUp-img {
  left: 11%;
  color: white;
  width: 28px;
}

@media (max-width: 490px) {
  .popUp-text {
    font-size: 28px;
  }
}

@media (max-width: 455px) {
  .popUp-text {
    font-size: 24px;
  }
  .popUp-img {
    width: 24px;
  }
}

@media (max-width: 420px) {
  .popUp-text {
    font-size: 20px;
  }
  .popUp-img {
    width: 22px;
  }
}

@media (max-width: 390px) {
  .popUp-text {
    padding: 20px;
    padding-top: 0;
  }
}

@media (max-width: 330px) {
  .popUp-text {
    font-size: 18px;
  }
  .popUp-img {
    width: 20px;
  }
}
